<template>
  <div>
    <b-tabs
      pills
    >
      <b-tab
        :title-link-class="'icon-panel-home'"
        @click="openMenuPanel"
      >
        <template #title>
          <feather-icon
            icon="ArrowLeftIcon"
            size="19"
          />
        </template>
      </b-tab>
      <b-tab
        title="GEPSL"
        active
      >
        <iframe
          width="100%"
          height="1400"
          src="https://gota.cosama.am.gov.br/painel/public/dashboard/9ea617c0-1e19-43eb-9a16-64d31aa709cc"
          frameborder="0"
          allowFullScreen="true"
        />
      </b-tab>
      <b-tab
        title="GTI"
        lazy
      >
        <iframe
          ref="iframe"
          width="100%"
          height="2690"
          src="https://gota.cosama.am.gov.br/painel/public/dashboard/33e2e53d-bb30-4aab-bc77-a738068a8b42"
          frameborder="0"
          allowFullScreen="true"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'

export default {
  data() {
    return {}
  },
  beforeCreate() {
    store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'hidden' })
  },
  beforeRouteLeave(to, from, next) {
    store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'floating' })
    next()
  },
  methods: {
    openMenuPanel() {
      router.push({ name: 'panel' })
    },
  },
}
</script>
<style>
  [dir] .nav-pills .nav-link {
    padding: 0.3rem 1.5rem;
    border: 1px solid transparent;
  }
  .icon-panel-home {
    padding: 0.1rem 0.8rem 0rem 0rem !important
  }
  .header-navbar.floating-nav {
    position: absolute !important;
  }
</style>

